import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Testimonials" />

    <div>
      <div className="section">
        <div className="container">
          <div className="section-title section-title-wide no-bottom-space">
            <h6 className="subheading text-primary-1">Millions of lives changed </h6>
            <h1 className="no-bottom-space">Testimonials</h1>
          </div>
        </div>
      </div>
      <div className="section no-bottom-space bg-gray-4">
        <div className="container">
          <div className="w-dyn-list">
            <div className="w-dyn-items">
              <div className="w-dyn-item">
                <div className="w-layout-grid featured-blog-post-grid"><Link to="/jenny/" className="w-inline-block"><img src="/images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ.jpg" alt="" sizes="(max-width: 479px) 94vw, (max-width: 767px) 89vw, (max-width: 991px) 502px, 95vw" srcSet="/images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ-p-800.jpeg 800w, /images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ.jpg 1047w" /></Link>
                  <div className="card-body featured-case-study-card-body">
                    <div>
                      <h3>"the program is already giving me a better attitude that’s making me take even better care of myself"</h3>
                    </div>
                    <div className="div-block-5"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-4">Jenny</h6><Link to="/jenny/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section no-bottom-space bg-gray-4">
        <div className="container">
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body">
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p>
                    <div className="div-block-3"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-2">Dorsey</h6><Link to="/dorsey/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body">
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p>
                    <div className="div-block-3"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-2">Annie Marie</h6><Link to="/annie-marie/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body">
                    <p className="case-study-card-excerpt"> It’s been decades since she picked up her first copy of The Love Tapes that has filled her life with “benefits and graces.”</p>
                    <div className="div-block-3"><img src="/fonts/5ddcc802eb72505d98d07190_20-People%20Avatar-Woman.svg" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-2">Julia</h6><Link to="/julia/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section no-bottom-space bg-gray-4">
        <div className="container">
          <div className="w-dyn-list">
            <div className="w-dyn-items">
              <div className="w-dyn-item">
                <div className="w-layout-grid featured-blog-post-grid"><Link to="/marlin/" className="w-inline-block"><img src="/images/5dc9b53847ea21dec6288d6c_a-person-stepping-on-scales-to-see-if-they-have-hit-a-weight-loss-plateau.jpg" alt="" /></Link>
                  <div className="card-body featured-case-study-card-body">
                    <div>
                      <h3>“In the past when I lost weight, I still thought of myself as fat so I always put the weight back on. Now, I’ve lost 70 pounds and I’m keeping it off because I’ve changed my habits."</h3>
                      <div className="div-block-2"><img width={69} src="/images/5ddc49cd315b3039bba6af51_13-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                        <div>
                          <h6 className="heading">Marlin</h6><Link to="/marlin/">Read their story</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body">
                    <p className="case-study-card-excerpt">Just as some families watch TV together, Rasheedah’s family listened to Wise Guides programs at the same time. </p>
                    <div className="div-block-4"><img src="/fonts/5ddce2572111937b8a12089b_11-People%20Avatar-Woman.svg" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-3">Rasheedah</h6><Link to="/rasheedah/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body">
                    <p className="case-study-card-excerpt">Prior to listening to the tapes, I feel I was more of an introvert. But now, I feel my personality has altered to more of an outgoing, social type of person.</p>
                    <div className="div-block-4"><img src="/fonts/5ddcdd30d52c2f2f73c63597_12-People%20Avatar-Man.svg" alt="" className="case-study-logo" />
                      <div>
                        <h6 className="heading-3">Tony</h6><Link to="/tony/">Read their story</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
